
function Logo() {
    return (
      <div className="Logo flex flex-col items-center">
        <p className="text-[4px] lg:text-[14px] text-[#0b85a4] font-bold">Cooper&Hunter фірмовий магазин</p>
        <img className="w-[70px] lg:w-[200px]" src="/images/logo.png" />
      </div>
    );
  }
  
  export default Logo;
