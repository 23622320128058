import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BackcallMailsender } from "../../services/operations/Productapi";
import ReCAPTCHA from "react-google-recaptcha";
import NewsBlock from "./NewsBlock";

function Backcall() {
  const intl = useIntl();
  const recaptchaRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // State to hold the captcha value
  const [captchaToken, setCaptchaToken] = useState(null);

  // Update captchaToken when the user completes the captcha
  const onCaptchaChange = (value) => {
    setCaptchaToken(value);
  };

  const MailHandler = async (data) => {

    const formdata = new FormData();
    formdata.append("username", data.username);
    formdata.append("email", data.email);
    formdata.append("message", data.message);
    formdata.append("phone", data.phone);

    if (!captchaToken) {
      toast.error("Please complete the captcha");
      return;
    }

    const response = await BackcallMailsender(formdata)();
    if (response) {
      toast.success("We have forwarded your complaint and we will get in touch with you shortly");
      reset();
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setCaptchaToken(null);
    }
  };

  return (
    <form
      className="Backcall flex flex-col flex-wrap mx-[-1em] w-[76%] lg:mr-0 lg:w-full m-2 gap-3 justify-center border-[1px] rounded-[10px] shadow-[0_1px_3px_1px_#d3d9d9] p-[5px_10px_5px_10px] bg-[#FFF] lg:ml-4"
      onSubmit={handleSubmit(MailHandler)}
    >
	  {/* <div className="flex w-full items-center justify-start">
        <div className="m-[10px] text-[12px]">
          <Link to="/catalog" className="underline text-[#0b85a4]">
            <FormattedMessage id="catalog" defaultMessage="Catalog" />
          </Link>
          <span>» <FormattedMessage id="menu.backcall" defaultMessage="" /></span>
        </div>
      </div>*/}
      <div className="flex flex-col items-start justify-start text-[#525252]">
        <br></br>
	<div>
          <span className="text-2xl text-[#333399] font-bold mb-3 text-left">
            <FormattedMessage id="backcall.title" defaultMessage="" />
          </span>
        </div>
        <div className="flex flex-col mt-5 gap-2 justify-start w-full text-[#6e8a99] text-[14px] ">
          <div className="flex justify-start">
            <FormattedMessage id="backcall.letterto" defaultMessage="Letter to the" />:
          </div>
          <div className="flex justify-start">
            <select>
              <option>Cooper&Hunter</option>
            </select>
          </div>
          <div>
            <div className="flex justify-start">
              <span>
                <FormattedMessage id="backcall.yourname" defaultMessage="Your name" />:
              </span>
            </div>
            <div className="flex justify-start">
              <input
                type="text"
                name="username"
                className="border-[1px] border-color-[#000000]"
                {...register("username", { required: true })}
              />
            </div>
          </div>
          {errors.username && <span className='text-red-500'>*username is required</span>}
          <div>
            <div className="flex justify-start">
              <span>Номер телефону</span>
            </div>
            <div className="flex justify-start">
              <input
                type="number"
                name="phone"
                className="border-[1px] border-color-[#000000]"
                {...register("phone", {
                  required: "phone is required",
                  pattern: {
                    value: /^[0-9]{10,15}$/,
                    message: "Enter a valid phone number"
                  }
                })}
              />
            </div>
          </div>
          {errors.phone && <span className='text-red-500'>{errors.phone.message}</span>}
          <div>
            <div className="flex justify-start">
              <span>
                <FormattedMessage id="backcall.email" defaultMessage="Електронна адреса" />:
              </span>
            </div>
            <div className="flex justify-start">
              <input
                type="email"
                name="email"
                className="border-[1px] border-color-[#000000]"
                {...register("email", {
                  required: "email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Enter a valid email address"
                  }
                })}
              />
            </div>
          </div>
          {errors.email && <span className='text-red-500'>{errors.email.message}</span>}
          <div>
            <div className="flex justify-start">
              <span>
                <FormattedMessage id="backcall.message" defaultMessage="Message" />:
              </span>
            </div>
            <div className="flex justify-start">
              <textarea
                name="message"
                className="border-[1px] border-color-[#000000] w-full"
                rows="15"
                cols="50"
                wrap="soft"
                {...register("message", { required: true })}
              />
            </div>
          </div>
          {errors.message && <span className='text-red-500'>*message is required</span>}
          <div className="flex justify-end">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LeqjeMqAAAAAJK9W5JbulrTu8crsUGeQq-W3cGY"
              onChange={onCaptchaChange}
              onExpired={() => setCaptchaToken(null)}
            />

            <button
              type="submit"
              className="m-3 p-3 bg-[#0b85a4] text-[14px] text-white rounded-[6px]"
            >
              <FormattedMessage id="backcall.continue" defaultMessage="Message" />
            </button>
          </div>
        </div>
      </div>
      <NewsBlock />
    </form>
  );
}

export default Backcall;
