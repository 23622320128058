import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactModal from 'react-modal';
import { createproduct, UserLogin } from '../../services/operations/Productapi';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { RxCross2 } from "react-icons/rx";

const AdminPage = ({ isOpen, onRequestClose, setModal }) => {

    useEffect(() => {
        // Set the app element to the root element with id "root"
        ReactModal.setAppElement('#root');
    }, []);


    const { login } = useSelector((state) => state.log);
    const [User, setUser] = useState(false);
    const dispatch = useDispatch();
    const [CategoryError, setCategoryError] = useState("");

    const Closehandler = () => {
        dispatch(setModal(false));
    }

    const categories = [
        'Wall Conditioners', 'Multi-split systems', 'Heat pumps', 'Duct air conditioners', 'Cassette air conditioners', 'Floor-to-ceiling', 'Air purifiers', 'Air humidifiers', 'Air dehumidifiers', 'Heaters', 'Air dryers'];

    const subcategories = [
        'Vital', 'Veritas', 'Arctic', 'Daytona', 'Supreme Continental', 'Majesty', 'ICY', 'Avalon', 'Imperial', 'VIP', 'Supreme', 'Consol', 'Nature', 'External','Vital(I)','Veritas(I)','Alpha(I)','Daytona(I)','Supreme Continental(I)','Cassette air conditioners(I)','Consol(I)','Duct air conditioners(I)'];

    const customStyles = {
        content: {
            width: '50%', // You can set the width as a percentage or in pixels
            height: '98%', // You can set the height as a percentage or in pixels
            margin: 'auto', // Center the modal
            backgroundColor: '#424854',
            borderRadius: '8px',
            padding: "0px",
            zIndex: 1000,
        },
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const LoginHandler = async (data) => {

        const formdata = new FormData();

        formdata.append("username", data.username)
        formdata.append("password", data.password)

        const response = await UserLogin(formdata)();

        if (response) {

            if (response.success) {
                setUser(true);
                toast.success("User logged in successfully")
            } else {
                toast.error("Incorrect Username and password")
            }
        }


    }

    const Clickhandler = async (data) => {
        if (data.category === "default") {
            setCategoryError(true);
            return;
        }

        const formdata = new FormData();

        formdata.append("name", data.name)
        formdata.append("description", data.description)
        formdata.append("main_functions", data.main_functions)
        formdata.append("category", data.category)
        formdata.append("subcategory", data.subcategory)
        formdata.append("Manufacturer", data.Manufacturer)
        formdata.append("Product_Weight", data.Product_Weight)
        formdata.append("Available_in_stock", data.Available_in_stock)
        formdata.append("Price", data.Price)
        formdata.append("ItemCode", data.ItemCode)
        formdata.append("ParentCode", data.ParentCode)
        formdata.append("Location", data.Location)


        const response = await createproduct(formdata)();

        if (response) {
            toast.success("Product created succesfully");
        }
    };

    return (

        <div>

            {
                User ? (
                    <div>
                        <ReactModal
                            isOpen={login}
                            onRequestClose={() => dispatch(setModal(false))}
                            contentLabel="AdminModal"
                            style={customStyles}
                        >

                            <div>

                                <form onSubmit={handleSubmit(Clickhandler)} className='flex flex-col mt-[60px] justify-center items-center gap-3'>

                                    <div className=' absolute text-white text-xl right-5 top-5 cursor-pointer' onClick={() => Closehandler()}><RxCross2 /></div>

                                    <h2>Add Product Form</h2>


                                    <input type='text' placeholder='Name' className=' p-2 rounded-md w-[40%]' {...register("name", { required: true })}></input>

                                    {
                                        errors.name && <span className='text-red-500'>*Name is Required</span>
                                    }

                                    <input type='text' placeholder='description' className=' p-2 rounded-md w-[40%]' {...register("description", { required: true })}></input>

                                    {
                                        errors.description && <span className='text-red-500'>*description is Required</span>
                                    }

                                    {
                                        CategoryError && <span className='text-red-500'>*category is Required</span>
                                    }

                                    <input type='text' placeholder='main_functions' className=' p-2 rounded-md w-[40%]' {...register("main_functions", { required: true })}></input>

                                    {
                                        errors.main_functions && <span className='text-red-500'>*main functions is Required</span>
                                    }

                                    <select type='text' placeholder='category' className=' p-2 rounded-md w-[40%]' defaultValue={"default"} name='category' {...register("category", { required: true })}>

                                        <option disabled value="default">--Choose a category--</option>

                                        {
                                            categories.map((category, index) => (
                                                <option value={category} key={index}>{category}</option>
                                            ))
                                        }


                                    </select>


                                    <span className='text-red-600'>* Use subcategory only when category is Wall Conditioners or Multi split systems</span>

                                    <select type='text' placeholder='subcategory' className=' p-2 rounded-md w-[40%]' defaultValue={"default"} name='category' {...register("subcategory")}>

                                        <option disabled value="default">--Choose a subcategory--</option>

                                        {
                                            subcategories.map((subcategory, index) => (
                                                <option value={subcategory} key={index}>{subcategory}</option>
                                            ))
                                        }
                                    </select>

                                    <input type='text' placeholder='Manufacturer' className=' p-2 rounded-md w-[40%]' {...register("Manufacturer", { required: true })}></input>

                                    {
                                        errors.Manufacturer && <span className='text-red-500'>*Manufacturer is Required</span>
                                    }

                                    <input type='number' placeholder='Product_Weight' className=' p-2 rounded-md w-[40%]' {...register("Product_Weight", { required: true })}></input>

                                    {
                                        errors.Product_Weight && <span className='text-red-500'>*Product Weight is Required</span>
                                    }

                                    <input type='number' placeholder='Available_in_stock' className=' p-2 rounded-md w-[40%]' {...register("Available_in_stock", { required: true })}></input>

                                    {
                                        errors.Product_Weight && <span className='text-red-500'>*Available_in_stock is Required</span>
                                    }

                                    <input type='number' placeholder='Price' className=' p-2 rounded-md w-[40%]' {...register("Price", { required: true })}></input>

                                    {
                                        errors.Price && <span className='text-red-500'>*Price is Required</span>
                                    }

                                    <input type='number' placeholder='ItemCode' className=' p-2 rounded-md w-[40%]' {...register("ItemCode", { required: true })}></input>

                                    {
                                        errors.ItemCode && <span className='text-red-500'>*ItemCode is Required</span>
                                    }

                                    <input type='number' placeholder='ParentCode' className=' p-2 rounded-md w-[40%]' {...register("ParentCode", { required: true })}></input>

                                    {
                                        errors.ParentCode && <span className='text-red-500'>*ParentCode is Required</span>
                                    }

                                    <input type='text' placeholder='Location' className=' p-2 rounded-md w-[40%]' {...register("Location", { required: true })}></input>

                                    {
                                        errors.Location && <span className='text-red-500'>*Location is Required</span>
                                    }

                                    <button className=" ml-4 border-black border-[3px] bg-blue-600 p-2 w-[10%] rounded-md">Submit</button>


                                </form>
                            </div>

                        </ReactModal>
                    </div>
                ) : (<div>

                    <ReactModal
                        isOpen={login}
                        onRequestClose={() => dispatch(setModal(false))}
                        contentLabel="AdminModal"
                        style={customStyles}>

                        <form className='flex flex-col mt-[60px] justify-center items-center gap-3' onSubmit={handleSubmit(LoginHandler)}>

                            <div className=' absolute text-white text-xl right-5 top-5 cursor-pointer' onClick={() => Closehandler()}><RxCross2 /></div>

                            <h2>Login as Admin</h2>


                            <input type='text' placeholder='Username' className=' p-2 rounded-md w-[40%]' {...register("username", { required: true })} />

                            <input type='password' placeholder='Password' className=' p-2 rounded-md w-[40%]' {...register("password", { required: true })} />

                            <button className=" ml-4 border-black border-[3px] bg-blue-600 p-2 w-[10%] rounded-md">Login</button>

                        </form>


                    </ReactModal>

                </div>)
            }

        </div>

    )
}

export default AdminPage;
