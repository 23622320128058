import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from "../slices/loginslice";
import cartSlice from "../slices/CartSlice";

const rootReducer = combineReducers({
    log:loginReducer,
    cart:cartSlice,
});

export default rootReducer;
