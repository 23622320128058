import axios from "axios";

export const axiosInstance = axios.create({});

export const apiConnector = (method, url, bodydata, header, params) => {
  const config = {
    method: method,
    url: url,
    headers: header ? header : null,
    params: params ? params : null,
  };

  if (method.toLowerCase() === "post" || method.toLowerCase() === "put") {

    if (bodydata instanceof FormData) {
      config.data = bodydata;
      config.headers = {
        ...config.headers,
        "Content-Type": "multipart/form-data",
      };

    } else {
      config.data = bodydata ? bodydata : null;
    }
  }

  return axiosInstance(config);
};
