import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl'; 
import Item from "../Product/Item";
import NewsBlock from "./NewsBlock";

function About() {
  const intl = useIntl(); // place this within your function / class.

  return (

    <div className="About flex flex-col flex-wrap ml-[+1em] w-[50%] lg:w-full m-2 gap-3 justify-center border-[1px] rounded-[10px] shadow-[0_1px_3px_1px_#d3d9d9] p-[5px_10px_5px_10px] bg-[#FFF] lg:ml-7 component-container">
	{/*<div className="flex w-full items-center justify-start">
        <div className="m-[10px] text-[12px]">
          <Link to="/catalog" className="underline text-[#0b85a4]">
              <FormattedMessage id="catalog" defaultMessage="Catalog" />
          </Link>
          <span>» <FormattedMessage id="menu.aboutus" defaultMessage="" /></span>
        </div>
      </div>*/}
      <div className="flex flex-col w-full justify-start">
	  {/*<div className="text-[16px] text-[#000000] font-bold mb-3 text-left">
          <FormattedMessage id="menu.aboutus" defaultMessage="" />
        </div>*/}
        <div className="whitespace-pre-line	text-left	text-[#6e8a99] text-[15px]">
          <h1 className="text-[#333399] font-bold">Про компанію Cooper&amp;Hunter</h1>
	  <p >Історія <a href="/catalog/Wall_Conditioners">кондиціонерів Сooper&amp;Hunter</a> (США), починається з об&#39;єднання зусиль трьох великих промислових підприємств на базі C.S. Cooper Company — Hunter Manufacturing Company і Brunner Manufacturing Company. В результаті чого в 1916 році була створена компанія Cooper&amp;Hunter International Corporation, напрямком якої є виробництво кондиціонерів Сooper&amp;Hunter.</p>

	   <p>Асортимент пропонованої продукції, що постійно зростає, представлений такими типами побутових та промислових кондиціонерів як: <a href="/prodazha/konditsionery-bytovye/nastennye/cooperhunter/">настінні</a>, <a href="https://www.cooper-hunter.com.ua/prodazha/kommercheskie-konditsionery/napolno-potolochnye/cooper-hunter/">консольні, підлогово-стельові</a>, <a href="/catalog/Wall_Conditioners/Veritas(I)">мультіспліт-системи</a>, мобільні, <a href="/catalog/Cassette_air_conditioners">касетні</a> та&nbsp;<a href="catalog/Duct_air_conditioners">канальні кондиціонери</a>.</p>
<p>У зв&#39;язку з поступовою забороною на використання в світі фреону R-22, що руйнує азоновий шар нашої планети, компанія почала з 2010 року випускати кондиціонери на озонобезпечному фреоні R-410; енергозбереженню,в моделі ряду кондиціонерів Cooper Hunter з&#39;явилися інвертерні моделі, що охоплюють широкий діапазон холодопродуктивності від 3,22 кВт до 7,04 кВт, які вироблені з використанням технологій і комплектующих <a href="https://www.daikin-ukraine.com">Daikin</a>.&nbsp;&nbsp;Крім цього є в асортименті теплове обладнання: електроконвектори, зволожувачі і&nbsp;<a href="catalog/Air_dryers">осушувачі повітря</a>.
<br></br>Кондиціонери C&amp;H виробляється на заводі Сooper&amp;Hunter International Corporation у м. Джухай (Zhuhai) за 40 км від Гонг-Конга – об&#39;єднаного виробничого та науково-експериментального майданчика компаній&nbsp;
<a href="https://gree-ukraine.com">GREE Electric Appliance, Inc</a>.і&nbsp;<a href="https://daikin-ukraine.com">Daikin Industries, Ltd</a>. <br></br><br></br><span ><strong>Виробнича база C&amp;H характеризується найвищим рівнем культури та стандартів виробництва:<br></br></strong></span><br></br>
<strong>Якість&nbsp;С&amp;H</strong>&nbsp;забезпечується 100% контролем комплектуючих, ретельним дотриманням технології складання та обов&#39;язковими випробуваннями кожної моделі. Виявлення навіть одного незначного дефекту, допущеного будь-ким із співробітників, обов&#39;язково стає предметом аналізу і несе за собою управлінські та адміністративні рішення як щодо того, хто допустив брак, так і всіх співробітників, які контролюють цей процес. Такі суворі стандарти забезпечують<strong> найвищу якість продукції, що випускається.</strong>
Сумарна виробнича потужність – 27 млн одиниць кліматичного обладнання: 20 категорій, 400 серій, 7 тис. моделей. За високий рівень якості завод включено до рейтингу FAB-50 Forbes.<br></br>Усі моделі, придбані в <a href="https://cooperandhunter.lviv.ua">офіційному фірмовому магазині Cooper&amp;Hunter</a>,
сертифіковані в Україні. 
Продукція С&amp;H має екологічний сертифікат OHSAS18000 – гарантія екологічної чистоти та безпеки, а також сертифікована ISO 9001, ISO 14000, CE, UL, УКРСЕПРО.<br></br>
</p>
	<br></br>
	<iframe width="640" height="360" src="https://www.youtube.com/embed/qSwTchrN59w" frameborder="0" allowfullscreen=""></iframe>
	<br></br>
<p>При виробництві <em>кліматичної техніки Cooper&amp;Hunter</em> застосовується спеціально розроблена система контролю якості, яка у поєднанні з високою дисциплінованістю персоналу та прагненням до створення досконалої продукції допомогла компанії досягти найвищої якості всіх виробів. Немалу роль у якісній та довговічній роботі кондиціонерів Cooper&amp;Hunter відіграє застосування компресорів відомих японських виробників таких як: Toshiba, Mitsubishi, Matsushita, Panasonic та Daikin.</p>
	<br></br>
	<p>В Україну <em>кондиціонери Cooper&amp;Hunter</em> поставляються з 2002 року і за цей час вже встигли зарекомендувати себе як надійні, тихі, економічні та витривалі, у сервісному центрі відомі випадки, коли кондиціонери Cooper&amp;Hunter працювали при високих навантаженнях без виконання сервісних робіт з очищення та доза. років. Але не варто відчувати кондиціонери на витривалість, у будь-якому випадку довговічну роботу кондиціонерів Cooper&amp;Hunter обумовлює правильний підбір потужності, своєчасне сервісне обслуговування та якісно вироблений <a href="/installation">монтаж</a>.</p>i
	<br></br>
		<p>Вся продукція компанії Cooper&amp;Hunter, яка постачається в Україну, має<em> офіційний гарантійний термін обслуговування — 2 роки</em> і може бути застосована як у побутових, так і складних промислових умовах.<br></br>У 2017 році компанією було представлено нові серії кондиціонерів: неінверторна&nbsp; Prima Plus, инверторна серія <a href="/catalog/Wall_Conditioners/Veritas">Veritas</a>, теплові насоси адаптовані для&nbsp;роботи до&nbsp;-25°C Nordic EVO II wi-fi та&nbsp;<a href="/catalog/Wall_Conditioners/Arctic">ARCTIC wi-fi</a>.</p>
	<br></br>
	<p>Йде в ногу зі світовими тенденціями і дизайнерський відділ компанії — кондиціонери здатні вписатися в будь-який навіть найвишуканіший інтер&#39;єр.<br></br>2018 ознаменувався появою серії&nbsp; EcoStar, виконаної в дизайнерському корпусі золотистого кольору та інверторної преміум серії&nbsp;<a href="catalog/Wall_Conditioners/Supreme">SUPREME</a>&nbsp;на новому фреоні R-32 у трьох варіантах кольору корпусу: Black, Gold та Silver.</p>
	<br></br>
		<p>В Україні бренд <em>C&amp;H</em> займає лідируючі позиції на кліматичному ринку завдяки чудовій якості та розумному рівню ціни на кондиціонери.<br></br>Устаткування Cooper&amp;Hunter є лідером у категорії «надійність», що підтверджується статистикою сервісних центрів професійних кліматичних компаній: два гарантійні випадки на 1000 проданих моделей.</p>


	{/* <FormattedMessage id="catalog_content"/> */}
        </div>
      </div>
      <NewsBlock />
    </div>
  );

}

export default About;
