import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { useIntl } from 'react-intl'; 
import Item from "../Product/Item";
import NewsBlock from "./NewsBlock";
import ItemThumb from "../Product/ItemThumb";
import { useEffect, useState } from "react";

function Models(props) {

  const [Name,setName] = useState(null);

  const {name} = useParams();


  return (
    <div className="Models flex flex-col flex-wrap ml-[-3em] w-[86%] lg:w-full m-2 justify-center border-[1px] rounded-[10px] shadow-[0_1px_3px_1px_#d3d9d9] p-[5px_10px_5px_10px] bg-[#FFF] lg:ml-4">
	 {/* <div className="flex w-full items-center justify-start">
        <div className="m-[10px] text-[12px]">
          <Link to="/catalog" className="underline text-[#0b85a4]">
              <FormattedMessage id="catalog" defaultMessage="Catalog" />
          </Link>
          <span>» 
            {
              name == 'air_conditioners' ? <FormattedMessage id="category.airconditioners" defaultMessage="Air conditioners" /> :
              name == 'Heat_pumps' ? <FormattedMessage id="category.heat_pumps" defaultMessage="Heat pumps" /> :
              name == 'Air_dehumidifiers' ? <FormattedMessage id="category.dehumidifiers" defaultMessage="Dehumidifiers" /> :
              name == 'Air_humidifiers' ? <FormattedMessage id="category.humidifiers" defaultMessage="Air humidifiers" /> : 
              name == 'Wall_Conditioners' ? <FormattedMessage id="category.wall_conditioners" defaultMessage="Wall conditioners" /> :
              name == 'Multi_split_systems' ? <FormattedMessage id="category.multi_split_systems" defaultMessage="Multi split systems" /> :
              name == 'Cassette_air_conditioners' ? <FormattedMessage id="category.cassette_conditioners" defaultMessage="Cassette conditioners" /> :
              name == 'Floor_to_ceiling' ? <FormattedMessage id="category.Floor_and_ceilings" defaultMessage="Floor and ceilings" /> : 
              name == 'Air_purifiers' ? <FormattedMessage id="category.Air_purifiers" defaultMessage="Air_purifiers" /> :
              name == 'Air_dryers' ? <FormattedMessage id="category.Air_dryers" defaultMessage="Air_dryers" /> :
              name == 'Heaters' ? <FormattedMessage id="category.heaters" defaultMessage="Heaters" /> : ''
            }
          </span>
        </div>
      </div>*/}
      <div className="flex flex-col w-full justify-start">
        <div className="text-[24px] text-[#333399] font-bold mb-3 text-left">
        {
              name == 'Duct_air_conditioners' ? <FormattedMessage id="category.airconditioners" defaultMessage="Air conditioners" /> :
              name == 'Heat_pumps' ? <FormattedMessage id="category.heat_pumps" defaultMessage="Heat pumps" /> :
              name == 'Air_dehumidifiers' ? <FormattedMessage id="category.dehumidifiers" defaultMessage="Dehumidifiers" /> :
              name == 'Air_humidifiers' ? <FormattedMessage id="category.humidifiers" defaultMessage="Air humidifiers" /> : 
              name == 'Wall_Conditioners' ? <FormattedMessage id="category.wall_conditioners" defaultMessage="Wall conditioners" /> :
              name == 'Multi_split_systems' ? <FormattedMessage id="category.multi_split_systems" defaultMessage="Multi split systems" /> :
              name == 'Cassette_air_conditioners' ? <FormattedMessage id="category.cassette_conditioners" defaultMessage="Cassette conditioners" />  : 
              name == 'Multi_split_systems' ? <FormattedMessage id="category.Multi_split_systems" defaultMessage="Multi split systems" /> :
              name == 'Floor_to_ceiling' ? <FormattedMessage id="category.Floor_and_ceilings" defaultMessage="Floor and ceilings" /> : 
              name == 'Air_purifiers' ? <FormattedMessage id="category.Air_purifiers" defaultMessage="Air_purifiers" /> :
              name == 'Air_dryers' ? <FormattedMessage id="category.Air_dryers" defaultMessage="Air dryers" /> : 
              name == 'Heaters' ? <FormattedMessage id="category.heaters" defaultMessage="Heaters" /> : ''
            }
        </div>
        {
          name == 'Duct_air_conditioners' ?
            <div className="grid grid-cols-2 md:grid-cols-3 items-center justify-center">
              <ItemThumb name={"Duct air conditioners"}/>
            </div> :
          name == 'Heat_pumps' ?
            <div className="grid grid-cols-2 md:grid-cols-3 items-center justify-center">
              <ItemThumb name={"Heat pumps"}/>
            </div> :
          name == 'Air_dehumidifiers' ?
            <div className="grid grid-cols-2 md:grid-cols-3 items-center justify-center">
              <ItemThumb name={"Air dehumidifiers"}/>
            </div> :
          name == 'Wall_Conditioners' ?
            <div className="grid grid-cols-2 md:grid-cols-3 items-center justify-center">
              <ItemThumb name={"Wall Conditioners"}/>
            </div> :
          name == 'Multi_split_systems' ?
            <div className="grid grid-cols-2 md:grid-cols-3 items-center justify-center">
              <ItemThumb name={"Multi-split systems"}/>
            </div> :
          name == 'Air_humidifiers' ?
            <div className="grid grid-cols-2 md:grid-cols-3 items-center justify-center">
              <ItemThumb name={"Air humidifiers"}/>
            </div> :
          name == 'Cassette_air_conditioners' ?
            <div className="grid grid-cols-2 md:grid-cols-3 items-center justify-center">
            <ItemThumb name={"Cassette air conditioners"}/>
            </div> :
          name == 'Floor_to_ceiling' ?
            <div className="grid grid-cols-2 md:grid-cols-3 items-center justify-center">
            <ItemThumb name={"Floor-to-ceiling"}/>
            </div> :
          name == 'Air_purifiers' ?
            <div className="grid grid-cols-2 md:grid-cols-3 items-center justify-center">
              <ItemThumb name={"Air purifiers"}/>
            </div> :
          name == 'Air_dryers' ?
            <div className="grid grid-cols-2 md:grid-cols-3 items-center justify-center">
              <ItemThumb name={"Air dryers"}/>
            </div> : 
          name == 'Heaters' ?
            <div className="grid grid-cols-2 md:grid-cols-3 items-center justify-center">
              <ItemThumb name={"Heaters"}/>
            </div> : <></>
        }
      </div>
	{/*	  
      <div className="text-[16px] text-[#da492b] mt-12 mb-3 text-left underline">
        <Link to="/products_new">
          <FormattedMessage id="catalog_new_items_link" defaultMessage="" />
        </Link>
      </div>*/}
      <div className="bg-[#d4d4d4] border-none h-[1px]" />
      {/* {items_desc} */}
      <NewsBlock />
    </div>
  );

}

export default Models;
