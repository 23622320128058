import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
function Menu() {
return (
<div className="Menu flex flex-col w-[138%] m-6 md:m-1 md:space-x-3 md:flex-row h-[55px] large3">
<div className="menu_spliter"></div>
<span className="flex text-[14px] p-1 text-[#0b85a4] items-center justify-center hover:text-[#FFF] hover:bg-[#F00] hover:rounded-[5px] hover:cursor-pointer">
<Link to="/catalog/Wall_Conditioners">
Фірмовий магазин
</Link>
</span>
<span className="flex text-[14px] p-1 text-[#0b85a4] items-center justify-center hover:text-[#FFF] hover:bg-[#F00] hover:rounded-[5px] hover:cursor-pointer">
<Link to="/about">
Про компанію C&H
</Link>
</span>
<div className="menu_spliter"></div>
<span className="flex text-[14px] p-1 text-[#0b85a4] items-center justify-center hover:text-[#FFF] hover:bg-[#F00] hover:rounded-[5px] hover:cursor-pointer">
<Link to="/installation">
Монтаж
</Link>
</span>
<div className="menu_spliter"></div>
<span className="flex text-[14px] p-1 text-[#0b85a4] items-center justify-center hover:text-[#FFF] hover:bg-[#F00] hover:rounded-[5px] hover:cursor-pointer">
<Link to="/warranty">
Гарантія та сервіс
</Link>
</span>
<div className="menu_spliter"></div>
<span className="flex text-[14px] p-1 text-[#0b85a4] items-center justify-center hover:text-[#FFF] hover:bg-[#F00] hover:rounded-[5px] hover:cursor-pointer">
<Link to="/contacts">
Контакти
</Link>
</span>
<div className="menu_spliter"></div>
<span className="flex text-[14px] p-1 text-[#0b85a4] items-center justify-center hover:text-[#FFF] hover:bg-[#F00] hover:rounded-[5px] hover:cursor-pointer">
<Link to="/articles">
Статті
</Link>
</span>
<div className="menu_spliter"></div>
<span className="flex text-[14px] p-1 text-[#0b85a4] items-center justify-center hover:text-[#FFF] hover:bg-[#F00] hover:rounded-[5px] hover:cursor-pointer">
<Link to="/backcall">
Зворотній звязок
</Link>
</span>
</div>
);
}
export default Menu;
