import Menu from "../components/header/Menu";
import { useState } from "react";
import AdminPage from "../components/content/AdminPage";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../slices/loginslice";
function Footer() {
const { login } = useSelector((state) => state.log);
const dispatch = useDispatch();
const Clickhandler = () => {
dispatch(setModal(true));
}
return (
<div className=" hidden lg:flex md:hidden flex-col flex-wrap w-[115%] mt-2 gap-3 justify-center lg:ml-[80px] large4">
<div className="flex w-full md:w-auto items-center justify-center md:justify-start large5">
<Menu />
</div>
<div className=" flex justify-center items-center">
<span className="text-[#d12425]">Cooperandhunter.net is a branded online store of Cooper&Hunter Ukraine.</span>
<div onClick={ () => Clickhandler()} className=" ml-4 border-black border-[3px] bg-blue-600 p-1 w-[100px] cursor-pointer">
Log in as Admin
</div>
</div>
{login && <AdminPage isOpen={login} setModal={setModal} onRequestClose={() => dispatch(setModal(false))}/>}
</div>
);
}
export default Footer;
