import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl'; 
import Item from "../Product/Item";
import NewsBlock from "./NewsBlock";

function Shipping() {
  const intl = useIntl(); // place this within your function / class.

  return (
    <div className="Shipping flex flex-col flex-wrap  ml-[-3em] w-[86%] lg:w-full m-2 gap-3 justify-center border-[1px] rounded-[10px] shadow-[0_1px_3px_1px_#d3d9d9] p-[5px_10px_5px_10px] bg-[#FFF] lg:ml-4">
      <div className="flex w-full items-center justify-start">
        <div className="m-[10px] text-[12px]">
          <Link to="/catalog" className="underline text-[#0b85a4]">
              <FormattedMessage id="catalog" defaultMessage="Catalog" />
          </Link>
          <span>» <FormattedMessage id="menu.shipping" defaultMessage="" /></span>
        </div>
      </div>
      <div className="flex flex-col w-full justify-start">
        <div className="text-[16px] text-[#000000] font-bold mb-3 text-left">
          <FormattedMessage id="menu.shipping" defaultMessage="" />
        </div>
        <div className="whitespace-pre-line	text-left	text-[#6e8a99] text-[15px]">
          <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: "aboutus_content"}) }} />
          {/* <FormattedMessage id="catalog_content"/> */}
        </div>
      </div>
      <NewsBlock />
    </div>
  );

}

export default Shipping;
