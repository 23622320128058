import { Link } from "react-router-dom";
import { getProducts } from "../../services/operations/Productapi";
import { useEffect, useState } from "react";
import { IoCheckbox } from "react-icons/io5";

function ItemThumb({ name }) {
  const [ProductData, setProductData] = useState("");
  const [exchange_rate, setexchange_rate] = useState(false);

  const fecher = async (data) => {
    const result = await getProducts({ data: data })();
    if (result) {
      setProductData(result);
      setexchange_rate("42");
    }
  };

  const imagePath = (Itemcode) => {
    try {
      if (Itemcode) {
        // Dynamically import the image
        const Path = require(`../../images/${Itemcode}.jpg`);
        return Path;
      } else {
        // Default image if no item code is provided
        const defaultPath = require(`../../images/multi-split-systems.jpg`);
        return defaultPath;
      }
    } catch (error) {
      // Handle missing image gracefully
      const fallbackPath = require(`../../images/multi-split-systems.jpg`);
      return fallbackPath;
    }
  };

  useEffect(() => {
    fecher(name);
  }, [name]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 lg:w-[300%] md:w-[300%] w-[200%] gap-3">
      {ProductData &&
        ProductData.data[0].models.map((details) => {
          // Extract the model number from the product name (for example: "CH-S07FTXF2-NG R32 Wi-Fi")
          const modelNumberMatch = details.name.match(/S(\d+)/);
          // Get the numeric part from the match if available
          const modelNumber = modelNumberMatch ? modelNumberMatch[1] : null;
          // Convert the numeric part to an integer
          const modelNumberInt = modelNumber ? parseInt(modelNumber, 10) : null;

          // Optionally, perform actions based on the extracted model number

          return (
            <div
              className="Item flex flex-col items-center justify-center border-[1px] border-gray-400 py-6 gap-3 text-[14px]"
              key={details._id}
            >
              <div>
                <img
                  src={imagePath(details.ItemCode)}
                  className="w-[200px]"
                  alt={details.name}
                />
              </div>
              <div className="flex flex-col items-center justify-center ml-4 ">
                <div>
                  <Link to={`subcategory/${details.name}`}>
                    <div className="cursor-pointer text-blue-600 text-xl font-bold ">
                      {details.name}
                    </div>
                  </Link>
                </div>

                {modelNumberInt !== null && (
                  <p className="text-sm text-gray-700 self-start text-[14px]">
                    {
                      modelNumberInt === 7 && "Площа приміщення до - 25" 
                    }
                    {
                      modelNumberInt === 9 && "Площа приміщення до - 25" 
                    }
                    {
                      modelNumberInt === 12 && "Площа приміщення до - 35" 
                    }
                    {
                      modelNumberInt === 18 && "Площа приміщення до - 50" 
                    }
                    {
                      modelNumberInt === 24 && "Площа приміщення до - 70" 
                    }
                  </p>
                )}

                <div className="flex items-center justify-center mt-4 gap-3">
                  <div>
                    <p className="flex items-center">
                      <IoCheckbox
                        className={`${
                          details.Available_in_stock > 0
                            ? "text-green-400"
                            : "text-gray-600"
                        }`}
                      />{" "}
                      {details.Available_in_stock
                        ? " В наявності "
                        : " Закінчився "}
                    </p>
                  </div>

                  <div>
                    <p className="bg-[#FFF7D2] w-[140px] px-[8px] py-[2px] text-xl font-bold">
                      {exchange_rate
                        ? Math.round(details.Price * exchange_rate)
                        : "Loading"}{" "}
                      UAH.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ItemThumb;
