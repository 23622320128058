import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

function NewsBlock() {
    return (
      <div className="flex flex-col justify-start mt-5 mb-6">
        <div className="flex justify-start">
	    {/*
	    <p1> <strong>  Новинний блок </strong> </h3>
	
          <span className="text-[#0b85a4] text-[14px] font-bold"><FormattedMessage id="latest_news" defaultMessage="" /></span>
       */}
	    <span className="text-[#0b85a4] text-[14px] font-bold">Новинний блок</span>
	</div>
        <div className="flex justify-start">
          <ul className="text-[11px] pt-4 text-left ml-4 list-disc">
            <li className="text-[#525252] underline text-wrap"><Link to="https://cooperandhunter.com/en/news/" className="text-[#0b85a4] text-wrap">Глобальні Новини Cooper&Hunter</Link></li>
            <li className="text-[#525251] underline text-wrap"><Link to="https://www.facebook.com/ecost.lv" className="text-[#0b85a4] text-wrap">Наша Facebook спільнота</Link></li>
	    {/*   <li className="text-[#525252] underline text-wrap"><Link to="" className="text-[#0b85a4] text-wrap">Air conditioners Cooper&Hunter Arctic Inverter series new 2015</Link></li>
            <li className="text-[#525252] underline text-wrap"><Link to="" className="text-[#0b85a4] text-wrap">New products for 2014. Cooper&Hunter Air-Master and Evolution series</Link></li>
            <li className="text-[#525252] underline text-wrap"><Link to="" className="text-[#0b85a4] text-wrap">Cooper&Hunter Smart R410A series analogue of the DELUXE series</Link></li>
          */}
	  </ul>
        </div>
      </div>
    );
  }
  
  export default NewsBlock;
