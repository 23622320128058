import React from 'react'
import image1 from "../../images/cooper-promo4.jpg";
import image2 from "../../images/cooper-promo5.jpg";
import image3 from "../../images/cooper-promo1.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import leftarrow from "../../images/left-arrow (1).png"
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
const Slider = () => {
return (
<div className='w-full hidden lg:block z-0 ml-4 large7'>
<div className="custom-swiper-prev">
<img src={leftarrow} alt="Previous" className='w-[50px] translate-x-[40px] translate-y-[160px] z-10 absolute cursor-pointer large9'/>
</div>
<Swiper
loop={true}
autoplay={{
delay: 2500,
disableOnInteraction: false,
}}
pagination={{
clickable: true,
}}
navigation={{
nextEl: '.custom-swiper-next',
prevEl: '.custom-swiper-prev',
}}
modules={[Autoplay, Pagination, Navigation]}
className=' w-[920px] z-0 large6'
>
<SwiperSlide>
<img src={image2} className=' rounded-md large8'/>
</SwiperSlide>
<SwiperSlide>
<img src={image1} className='rounded-md large8'/>
</SwiperSlide>
<SwiperSlide>
<img src={image3} className='rounded-md large8'/>
</SwiperSlide>
</Swiper>
<div className="custom-swiper-next">
<img src={leftarrow} alt="Previous" className='w-[50px] translate-x-[800px] translate-y-[-110px] z-10 absolute rotate-180 cursor-pointer large10'/>
</div>
</div>
)
}
export default Slider
