import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl'; 
import Item from "./Item";
import NewsBlock from "../content/NewsBlock";

function ModelItems(props) {
    const intl = useIntl();
    const items = [1, 2, 3];

    const items_desc = items.map((val, index) => {
      if (index < items.length - 1)
        return <>
          <Item />
          <div className="bg-[#d4d4d4] border-none h-[1px]" />
        </>
      else
        return <Item />
    })
    return (
      <div className="ModelItems flex flex-col flex-wrap w-full m-2 gap-3 justify-center border-[1px] rounded-[10px] shadow-[0_1px_3px_1px_#d3d9d9] p-[5px_10px_5px_10px] bg-[#FFF]">
        <div className="flex w-full items-center justify-start">
          <div className="m-[10px] text-[12px]">
            <Link to="/catalog" className="underline text-[#0b85a4]">
                <FormattedMessage id="catalog" defaultMessage="Catalog" />
            </Link>
            <Link to="" className="underline text-[#0b85a4]"> » 
              {
                props.category == 'air_conditioners' ? <FormattedMessage id="category.airconditioners" defaultMessage="Air conditioners" /> :
                props.category == 'heaters' ? <FormattedMessage id="category.heaters" defaultMessage="Heaters" /> :
                props.category == 'dehumidifiers' ? <FormattedMessage id="category.dehumidifiers" defaultMessage="Dehumidifiers" /> :
                props.category == 'humidifiers' ? <FormattedMessage id="category.humidifiers" defaultMessage="Humidifiers" /> : ''
              }
            </Link>
            <Link to="" className="underline text-[#0b85a4]"> » 
              {
                props.model
              }
            </Link>
          </div>
        </div>
        <div className="flex flex-col w-full justify-start md:flex-row">
          <div className="text-[16px] text-[#000000] font-bold mb-3 text-left">
            {
              props.model
            }
          </div>
          <div className="ml-auto">
            <img src="/images/product_images/avalong_1.jpg" />
          </div>
        </div>
        <div className="flex flex-col w-full justify-start">
          Description
          { items_desc }
        </div>
        <NewsBlock />
      </div>
    );

}

export default ModelItems;