import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { RiArrowRightSFill } from "react-icons/ri";
import pdf from "../../images/cooper-2025.pdf"
import image from "../../images/catalog-2025.jpeg"
const SecondMenu = ({ small }) => {

  const [show_wall, setshow_wall] = useState(false);
  const [show_wall1, setshow_wall1] = useState(false);

  const vitalSectionRef = useRef(null);

  // Function to handle smooth scrolling
  const scrollToVital = () => {
    if (vitalSectionRef.current && small) {
      vitalSectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };


  return (
    <div className={` ${small ? "bg-[#ECECEC]" : "bg-white w-[240px]"}`}
    >

      <ul className=' bg-[#f2f2f2b9] '>

        <h2 className='border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-5 flex items-center lg.justify-center text-blue-700 cursor-pointer text-[14px]'
          onClick={() => {
            setshow_wall(!show_wall)
          }}> <span><RiArrowRightSFill className={`text-lg ${show_wall ? "rotate-90" : " "} transition-all duration-500 `} /></span> Настінні кондиціонери</h2>
        <div className={`${show_wall ? " scale-y-100 h-[350px] " : " scale-y-0 h-[0px]"} transition-all duration-500 origin-top`}>

          <Link to={"/catalog/Wall_Conditioners/Vital"}
            onClick={() => {
              scrollToVital()
            }}>
	  
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Vital</li>
          </Link>
	{/*
          <Link to={"/catalog/Wall_Conditioners/Alpha"}
            onClick={() => {
              scrollToVital()
            }}>
	  
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Alpha</li>
          </Link>
	  */}
          <Link to={"/catalog/Wall_Conditioners/Veritas"}
            onClick={() => {
              scrollToVital()
            }}>
	   
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Veritas</li>
          </Link>
          <Link to={"/catalog/Wall_Conditioners/Arctic"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Arctic</li>
          </Link>
          <Link to={"/catalog/Wall_Conditioners/Daytona"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Daytona</li>
          </Link>
          <Link to={"/catalog/Wall_Conditioners/Supreme Continental"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Supreme Continental</li>
          </Link>
          <Link to={"/catalog/Wall_Conditioners/Majesty"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Majesty</li>
          </Link>
	  {/*
	  <Link to={"/catalog/Wall_Conditioners/ICY"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />ICY ІІІ</li>
          </Link>
          <Link to={"/catalog/Wall_Conditioners/Avalon"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Avalon</li>
          </Link>
          <Link to={"/catalog/Wall_Conditioners/Imperial"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Imperial</li>
          </Link>
	  */}
          <Link to={"/catalog/Wall_Conditioners/VIP"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />VIP</li>
          </Link>
          <Link to={"/catalog/Wall_Conditioners/Supreme"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Supreme</li>
          </Link>
   
	  <Link to={"/catalog/Wall_Conditioners/Nature"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Nature</li>

	 </Link>
         
	  <Link to={"/catalog/Wall_Conditioners/Consol"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Косольні</li>
          </Link>


        </div>

        <h2 className='border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 flex text-blue-700 cursor-pointer text-[14px] ml-3'
          onClick={() => setshow_wall1(!show_wall1)}> <span><RiArrowRightSFill className={`text-lg ${show_wall1 ? "rotate-90" : " "} transition-all duration-500 `} /></span>Мульти-спліт системи</h2>

        <div className={`${show_wall1 ? " scale-y-100 h-[275px] " : " scale-y-0 h-[0px]"} transition-all duration-500 origin-top`}>

          <Link to={"/catalog/Multi_split_Systems/External"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Зовнішні блоки</li>
          </Link>
          <Link to={"/catalog/Multi_split_Systems/Vital(I)"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Vital(I)</li>
          </Link>
          <Link to={"/catalog/Multi_split_Systems/Veritas(I)"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Veritas(I)</li>
          </Link>
	  {/*
          <Link to={"/catalog/Wall_Conditioners/Alpha(I)"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Alpha(I)</li>
          </Link>
*/}
          <Link to={"/catalog/Multi_split_Systems/Daytona(I)"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Daytona(I)</li>
          </Link>
          <Link to={"/catalog/Multi_split_Systems/Supreme Continental(I)"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Supreme Continental(I)</li>
          </Link>
          <Link to={"/catalog/Multi_split_Systems/Cassette air conditioners(I)"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Касетні внутрішні блоки</li>
          </Link>
          <Link to={"/catalog/Multi_split_Systems/Consol(I)"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Консольні внутрішні блоки</li>
          </Link>
          <Link to={"/catalog/Multi_split_Systems/Duct air conditioners(I)"}
            onClick={() => {
              scrollToVital()
            }}>
            <li className='flex items-center justify-start border-b-[#a8a8a8b9] border-solid border-b-[1px]  p-2 cursor-pointer' > <RiArrowRightSFill className=' text-lg' />Канальні внутрішні блоки</li>
          </Link>

        </div>

        <Link to={"/catalog/Heat_pumps"}
          onClick={() => {
            scrollToVital()
          }}>
          <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700 text-[14px] ml-8'>Теплові насоси</h2>
        </Link>
        <Link to={"/catalog/Duct_air_conditioners"}
          onClick={() => {
            scrollToVital()
          }}>
          <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700 text-[14px] ml-8'>Канальні кондиціонери</h2>
        </Link>
        <Link to={"/catalog/Cassette_air_conditioners"}
          onClick={() => {
            scrollToVital()
          }}>
          <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700 text-[14px] ml-8'>Касетні кондиціонери</h2>
        </Link>
        <Link to={"/catalog/Floor_to_ceiling"}
          onClick={() => {
            scrollToVital()
          }}>
          <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700 text-[14px] ml-8'>Підлогово-стелеві</h2>
        </Link>
	  {/*
        <Link to={"/catalog/Air_purifiers"}
          onClick={() => {
            scrollToVital()
          }}>
          <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700 text-[14px] ml-8'>Air purifiers</h2>
        </Link>
        <Link to={"/catalog/Air_humidifiers"}
          onClick={() => {
            scrollToVital()
          }}>
          <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700 text-[14px] ml-8'>Air humidifiers</h2>
        </Link>
	  */}
        <Link to={"/catalog/Air_dryers"}
          onClick={() => {
            scrollToVital()
          }}>
          <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700 text-[14px] ml-8'>Осушувачі повітря</h2>
        </Link>
	  {/*
        <Link to={"/catalog/Heaters"}
          onClick={() => {
            scrollToVital()
          }}>
          <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700 text-[14px] ml-8'>Heaters</h2>
        </Link>
        <Link to={"/catalog/Air_dehumidifiers"}
          onClick={() => {
            scrollToVital()
          }}>
          <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700 text-[14px] ml-8'>Dehumidifiers</h2>
        </Link>
	  */}
      </ul>
     
{
        !small &&
        <div className=' m-2'>
          <a href={pdf} target='blank'>
            <img src={image}></img>
          </a>
        </div>
      }

	<div ref={vitalSectionRef} ></div>
    </div>
  )
}

export default SecondMenu;
