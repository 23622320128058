import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removefromcart, resetcart } from '../../slices/CartSlice'
import { RiDeleteBin6Line } from "react-icons/ri";
import { useForm } from 'react-hook-form';
import { Mailsender } from '../../services/operations/Productapi';
import toast from 'react-hot-toast';
import ReCAPTCHA from "react-google-recaptcha";
import { v4 as uuidv4 } from 'uuid';

function Cart2() {

    const { cart, price, totalItems } = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const [form, setform] = useState(false);
    const [exchange_rate, setexchange_rate] = useState(0);
    const recaptchaRef = useRef(null);
    const [captchaToken, setCaptchaToken] = useState(null);

    const onCaptchaChange = (value) => {
        setCaptchaToken(value);
    };


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();



    const imagePath = (Itemcode) => {
        try {
            if (Itemcode) {
                // Dynamically import the image
                const Path = require(`../../images/${Itemcode}.jpg`);
                return Path;
            } else {
                // Default image if no item code is provided
                const defaultPath = require(`../../images/multi-split-systems.jpg`);
                return defaultPath;
            }
        } catch (error) {
            // Handle missing image gracefully
            const fallbackPath = require(`../../images/multi-split-systems.jpg`);
            return fallbackPath;
        }
    };

    const MailHandler = async (data) => {

        const formdata = new FormData();
        const orderid = uuidv4().split('-')[0];

        formdata.append("OrderId", orderid)
        formdata.append("Phone", data.Phone)
        formdata.append("Name", data.Name)
        formdata.append("Email", data.Email)
        formdata.append("Products", data.Products);
        formdata.append("Products_price", data.Products_price);
        formdata.append("Products_code", data.Products_code);
        data.notes && formdata.append("message", data.notes);

        if (!captchaToken) {
            toast.error("Please complete the captcha");
            return;
        }

        const response = await Mailsender(formdata)();

        if (response) {

            toast.success("Замовлення сформовано успішно ми звязжемось з Вами в найкоротший час");
            dispatch(resetcart());
            setform(false);
        }


    }

    useEffect(() => {
        setexchange_rate(42);
    }, [])

    return (
        <div className="flex flex-col flex-wrap mx-[-1em] w-[96%] lg:w-full m-2 gap-3 justify-center border-[1px] rounded-[10px] shadow-[0_1px_3px_1px_#d3d9d9] p-[5px_10px_5px_10px] bg-[#FFF] lg:ml-4 overflow-x-hidden">

            <div className="flex flex-col w-full justify-start">

                <h1 className='font-inter text-[30px] font-[500] leading-[38px] tracking-[0em] text-[#333399] font-bold text-center text-richblack-25 ml-[5rem] mt-10'>Моє замовлення</h1>

                <p className='ml-[5rem] mt-10 font-inter text-[16px] font-[400] leading-[22px] tracking-[1px] text-center text-richblack-500'>{totalItems} Товар(и) в корзині</p>

                {
                    form ?
                        <div className='text-black flex flex-col items-center ml-12 overflow-hidden overflow-x-hidden w-[100%] h-full mt-10 gap-10'>
                            <h2 className=' text-lg'>Прохання заповнити форму і ми зв&#884;яжемось з вами в найкоротший час</h2>

                            <form className=' flex flex-col gap-5 items-center text-black' onSubmit={handleSubmit(MailHandler)}>

                                <input
                                    type='text'
                                    className="border-[1px] border-[#000000] rounded-md px-10 py-1"
                                    placeholder='Ім&#884;я'
                                    {...register("Name", { required: true })}
                                />
                                {errors.Name && <span className='text-red-500'>*Name is required</span>}

                                <input
                                    type='number'
                                    className="border-[1px] border-[#000000] rounded-md px-10 py-1"
                                    placeholder='Номер телефону'
                                    {...register("Phone", {
                                        required: "Номер телефону обов&#884;язковий",
                                        pattern: {
                                            value: /^[0-9]{10,15}$/, // Adjust range based on country
                                            message: "Введіть коректний номер телефону"
                                        }
                                    })}
                                />
                                {errors.Phone && <span className='text-red-500'>{errors.Phone.message}</span>}

                                <input
                                    type='email'
                                    className="border-[1px] border-[#000000] rounded-md px-10 py-1"
                                    placeholder='Електронна пошта'
                                    {...register("Email", {
                                        required: "Електронна пошта обов&#884;язкова",
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: "Ведеіть коректун поштову адресу"
                                        }
                                    })}
                                />
                                {errors.Email && <span className='text-red-500'>{errors.Email.message}</span>}

                                <textarea
                                    name="message"
                                    className="border-[1px] border-[#000000] rounded-md w-full p-1"
                                    rows="7"
                                    cols="50"
                                    wrap="soft"
                                    placeholder='Ваш коментар(опційно)'
                                    {...register("notes")}
                                />

                                <h2 className=' text-black text-lg'>Товар(и) :</h2>

                                {
                                    cart.map((Item, index) => (
                                        <div className='flex items-center gap-4' key={Item.ItemCode}>
                                            <label htmlFor={`product-${Item.ItemCode}`} className='p-2 rounded-md bg-white'>
                                                {index + 1} - {Item.name}
                                            </label>
                                            <input
                                                id={`product-${Item.ItemCode}`}
                                                type='checkbox'
                                                defaultChecked
                                                value={Item.name}  // Set the value explicitly
                                                {...register("Products", { required: true })}
                                                className='p-2 rounded-md h-[30px] hidden'
                                            />

                                            <label htmlFor={`product-${Item.ItemCode}`} className='p-2 rounded-md bg-white '>
                                                Price - {exchange_rate ? Math.round(Item.Price * exchange_rate) : "Loading"} UAH.
                                            </label>

                                            <input
                                                id={`product-${Item.Price}`}
                                                type='checkbox'
                                                defaultChecked
                                                value={Math.round(Item.Price * exchange_rate)}  // Set the value explicitly
                                                {...register("Products_price", { required: true })}
                                                className='p-2 rounded-md h-[30px] hidden'
                                            />

                                            <label htmlFor={`product-${Item.ItemCode}`} className='p-2 rounded-md bg-white '>
                                                ItemCode - {Item.ItemCode}
                                            </label>

                                            <input
                                                id={`product-${Item.ItemCode}`}
                                                type='checkbox'
                                                defaultChecked
                                                value={Item.ItemCode}  // Set the value explicitly
                                                {...register("Products_code", { required: true })}
                                                className='p-2 rounded-md h-[30px] hidden'
                                            />
                                        </div>
                                    ))
                                }


                                <div className=' text-white'>Загальна вартість : {cart.length}</div>

                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LeqjeMqAAAAAJK9W5JbulrTu8crsUGeQq-W3cGY"
                                    onChange={onCaptchaChange}
                                    onExpired={() => setCaptchaToken(null)}
                                />

                                <button className="bg-[#2082D9] text-white p-1 rounded-md w-[200px] mt-6">Підтвердити</button>

                            </form>

                            <button className="bg-[#2082D9] text-white p-1 rounded-md w-[200px] mt-6" onClick={() => setform(false)}>Назад</button>
                        </div>
                        :

                        <div className='flex flex-col mt-10 items-start min-h-[100vh]'>





                            {
                                cart.length === 0 ? (<p className=' font-inter text-[30px] font-[500] leading-[38px] tracking-[0em] mx-auto text-richblack-25 mt-10 pl-[90px]'>Ваша корзина пуста </p>) :

                                    (
                                        <div className='flex flex-col mx-auto'>
                                            {cart.map((Itemdata, index) =>

                                            (<div className='flex border-t-[1px] border-richblack-600 pt-6 justify-around gap-2 lg:gap-10 w-[50vw]' key={index}>
                                                <img src={imagePath(Itemdata.ItemCode)} className='lg:w-[150px] lg:h-[100px] w-[50px] h-[50px] rounded-xl'></img>

                                                <div className='flex flex-col gap-5'>
                                                    <p className='text-richblack-500 lg:text-lg text-[12px]'>{Itemdata.name}</p>
                                                </div>

                                                <div className='flex flex-col items-center right-8'>
                                                    <button className='lg:flex hidden gap-3 items-center bg-richblack-600 px-4 py-3 rounded-xl text-red-1' onClick={() => dispatch(removefromcart(Itemdata))}><span><RiDeleteBin6Line /></span>Видалити</button>

                                                    <button className='flex gap-3 items-center lg:hidden bg-richblack-600 px-4 py-3 rounded-xl text-red-1' onClick={() => dispatch(removefromcart(Itemdata))}><span><RiDeleteBin6Line /></span></button>

                                                    <p className=' text-yellow-600 font-inter lg:block hidden text-[30px] font-[500] leading-[38px] tracking-[0em] text-left '>{exchange_rate ? Math.round(Itemdata.Price * exchange_rate) : "Loading"} <span className=' text-sm'>грн.</span></p>
                                                </div>
                                            </div>))}
                                        </div>)
                            }


                            {
                                cart.length > 0 &&

                                <div className=' flex lg:flex-row flex-col items-center gap-10 lg:justify-evenly w-full lg:items-center mt-[7%]'>
                                    <div className=' w-[200px]'></div>

                                    <button className="bg-[#2082D9] text-white lg:text-lg lg:p-2 p-1 rounded-md mx-auto" onClick={() => setform(true)}>Оформити замовлення</button>

                                    <div className='bg-richblack-700 w-[250px] rounded-xl flex flex-col items-center'>
                                        <p className=' text-richblack-400'>Загальна вартість: </p>
                                        <p className=' font-inter text-[23px] lg:text-[30px] font-[500] leading-[38px] tracking-[0em] '>{exchange_rate ? Math.round(price * exchange_rate) : "Loading"}<span className='text-sm'>грн.</span></p>
                                    </div>
                                </div>


                            }

                        </div>




                }

            </div>
        </div>
    );

}

export default Cart2;
