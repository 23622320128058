import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl'; 
import Item from "../Product/Item";
import NewsBlock from "./NewsBlock";

function Contacts() {
  const intl = useIntl(); // place this within your function / class.

  return (
    <div className="Contacts flex flex-col flex-wrap  ml-[-3em] w-[86%] lg:w-full m-2 gap-3 justify-center border-[1px] rounded-[10px] shadow-[0_1px_3px_1px_#d3d9d9] p-[5px_10px_5px_10px] bg-[#FFF] lg:ml-7">
	{/* <div className="flex w-full items-center justify-start">
        <div className="m-[10px] text-[12px]">
          <Link to="/catalog" className="underline text-[#0b85a4]">
              <FormattedMessage id="catalog" defaultMessage="Catalog" />
          </Link>
          <span>» <FormattedMessage id="menu.contacts" defaultMessage="" /></span>
        </div>
      </div>*/}
      <div className="flex flex-col w-full justify-start">
	  {/*<div className="text-[16px] text-[#000000] font-bold mb-3 text-left">
          <FormattedMessage id="menu.contacts" defaultMessage="" />
        </div> */}
        <div className="whitespace-pre-line	text-left	text-[#6e8a99] text-[15px]">
        
        <h1 className="text-[#333399] font-bold">Фірмовий магазин Cooper&amp;Hunter в&nbsp;Україні</h1> <br></br>
		<p><a href="/prodazha/konditsionery-bytovye/nastennye/cooperhunter/">Кондиціонери Cooper&amp;Hunter</a> з&nbsp;офіційною гарантією!<br><	/br><br></br>
		Адреса офісу:<b> м. Львів, вул. Калнишевського, 16/1 </b> <br></br> <br></br></p>

		тел.: (068) 310-15-65
	<br></br>
		<br></br>

	<div class="embed-responsive embed-responsive-16by9">
	  <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d2572.580822175923!2d23.9671195!3d49.8503333!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDnCsDUxJzAxLjIiTiAyM8KwNTgnMTAuOSJF!5e0!3m2!1sen!2sin!4v1737386647981!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
	</div>

	  {/* <FormattedMessage id="catalog_content"/> */}
        </div>
      </div>
      <NewsBlock />
    </div>
  );

}

export default Contacts;
