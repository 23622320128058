export const endpoints = {
    GET_PRODUCTS_URL : "https://api.cooperandhunter.lviv.ua/api/v1/getProducts",
    GET_MODELS_URL : "https://api.cooperandhunter.lviv.ua/api/v1/getModels",
    GET_MODELS_NAME_URL : "https://api.cooperandhunter.lviv.ua/api/v1/getModelsname",
    CREATE_PRODUCT_URL : "https://api.cooperandhunter.lviv.ua/api/v1/createModels",
    LOGIN_URL : "https://api.cooperandhunter.lviv.ua/api/v1/login",
    MAIL_URL : "https://api.cooperandhunter.lviv.ua/api/v1/sendOrderSuccessMail",
    BACKCALL_MAIL_URL : "https://api.cooperandhunter.lviv.ua/api/v1/BackcallMail",
}




// export const endpoints = {
//     GET_PRODUCTS_URL : "http://192.168.1.8:4000/api/v1/getProducts",
//     GET_MODELS_URL : "http://192.168.1.8:4000/api/v1/getModels",
//     GET_MODELS_NAME_URL : "http://192.168.1.8:4000/api/v1/getModelsname",
//     CREATE_PRODUCT_URL : "http://192.168.1.8:4000/api/v1/createModels",
//     LOGIN_URL : "http://192.168.1.8:4000/api/v1/login",
//     MAIL_URL : "http://192.168.1.8:4000/api/v1/sendOrderSuccessMail",
//     BACKCALL_MAIL_URL : "http://192.168.1.8:4000/api/v1/BackcallMail",
// }


// http://192.168.1.6:4000/
