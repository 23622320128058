import { createSlice } from "@reduxjs/toolkit";

const initialstate = {
    login : false,
};

const loginSlice = createSlice({
    name: "login",
    initialState: initialstate,
    reducers: {
        setModal(state,value) {
            state.login = value.payload
        },
    }
})

export const {setModal} = loginSlice.actions;

export default loginSlice.reducer;
