import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl'; 
import Item from "../Product/Item";
import NewsBlock from "./NewsBlock";

function Warranty() {
  const intl = useIntl(); // place this within your function / class.

  return (
    <div className="Warranty flex flex-col flex-wrap  ml-[-3em] w-[86%] lg:w-full m-2 gap-3 justify-center border-[1px] rounded-[10px] shadow-[0_1px_3px_1px_#d3d9d9] p-[5px_10px_5px_10px] bg-[#FFF] lg:ml-7">
      {/* <div className="flex w-full items-center justify-start">
        <div className="m-[10px] text-[12px]">
          <Link to="/catalog" className="underline text-[#0b85a4]">
              <FormattedMessage id="catalog" defaultMessage="Catalog" />
          </Link>
          <span>» <FormattedMessage id="menu.warranty" defaultMessage="" /></span>
        </div> 
      </div>*/}
      <div className="flex flex-col w-full justify-start text-[#6e8a99] text-[15px]">
         {/* <div className="text-[16px] text-[#000000] font-bold mb-3 text-left">
          <FormattedMessage id="menu.warranty" defaultMessage="" />
        </div> */}

	  <h1 className="text-[#333399] text-center font-bold">Авторизований сервісний центр COOPER&amp;HUNTER</h1>

          <div class="alert alert-info"> 
	  	  
	<p className="text-[#ff0000] text-center font-bold">Замовлення сервісного обслуговування кондиціонера</p>
	<p className="text-[#000000] text-center font-bold">(чистка внутрішнього і зовнішнього блоків/прочистка дренажу/заправка фреоном):</p>
	<p className="text-[#ff0000] text-center font-bold"><a href="tel:+380973101565">(097) 310-15-65</a></p>
	<p className="text-#808080] text-center font-bold">також виконуємо очистку кондиціонерів інших брендів</p>

	<br></br>
	<p className="text-[#000000] text-left font-bold">Питання щодо гарантії та ремонту обладнання і запчастин: </p><a href="tel:+380973101565">(096) 310-15-65</a>
	<p className="text-[#000000] text-left font-bold">Графік роботи лінії:</p> пн-пт 9:00-17:00, сб-нд вихідний.
	<br></br>
	<p className="text-[#000000]"> Адреса офісу: <b>м. Львів, вул. Калнишевського, 16/1</b></p>
	  <br></br>


 </div>

	 <div class="embed-responsive embed-responsive-16by9">
          <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d2572.580822175923!2d23.9671195!3d49.8503333!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDnCsDUxJzAxLjIiTiAyM8KwNTgnMTAuOSJF!5e0!3m2!1sen!2sin!4v1737386647981!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
	   <br></br>
         <p><strong>Сервісний центр «Купер Хантер»</strong> - це не просто гарантійне та післягарантійне обслуговування кліматичної техніки, а цілий комплекс заходів із супроводу партнерів.<br></br>Новий інтегрований <strong><em>сервісний центр у Львівській та Тернопільській областях</em></strong> є одним із базових у системі сервісного обслуговування та підготовки технічних фахівців C&amp;H у Європі.</p>
	<h2>Сервісне обслуговування кондиціонерів Cooper&amp;Hunter</h2>
	<p><em>Сервісне обслуговування для&nbsp;кондиціонерів</em> необхідно для&nbsp;довговічної роботи та&nbsp;<strong>не&nbsp;є гарантійним</strong>. Під час експлуатації кондиціонера <strong>зовнішній і&nbsp;внутрішній блоки засмічуються</strong> пилом, пліснявою, павутиною, пухом і т.д. Крім цього, <strong>може забиватися дренажна система</strong>. При забрудненні порушується нормальний обдув теплообмінника, що веде до погіршення продуктивності.</p>
	
	<p>Технічне обслуговування кондиціонерів «Купер Хантер» &nbsp;<strong>бажано&nbsp;виконувати два&nbsp;рази на&nbsp;рік,</strong>перед літнім сезоном – навесні і якщо Ви збираєтеся користуватися кондиціонером в зимовий період для обігріву приміщення, або наприклад, для охолодження серверів – восени. Не рідкісні приклади, коли за кондиціонерами люди не стежать роками, тут вже як пощастить, але буває, що апарат може служити без сервісного обслуговування довгий час, наскільки таких чинників, як таких, як , наявність тополь, близькість до транспортних магістралей і т.д.</p>

      </div>
	
      <NewsBlock />
    </div>
  );

}

export default Warranty;
