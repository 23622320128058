import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialstate = {
    cart : localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
    totalItems : localStorage.getItem("totalItems") ? JSON.parse(localStorage.getItem("totalItems")) : 0,
    price : localStorage.getItem("price") ? JSON.parse(localStorage.getItem("price")) : null
};

const cartSlice = createSlice({
    name: "cart",
    initialState: initialstate,
    reducers: {
        addtocart : (state,value) => {
            const course = value.payload;
            console.log(value.payload);
            const index = state.cart.findIndex((item) => item._id === course._id);

            if (index >= 0) {
                toast.error("Product already in the cart");
                return;
            }

            state.cart.push(course);
            state.totalItems++;
            state.price += JSON.parse(course.Price);

            localStorage.setItem("cart", JSON.stringify(state.cart));
            localStorage.setItem("totalItems", JSON.stringify(state.totalItems));
            localStorage.setItem("price", JSON.stringify(state.price));

            toast.success("Product added to cart");
        },
        removefromcart : (state,value) => {
            const course = value.payload;
            console.log(value.payload);
            const index = state.cart.findIndex((item) => item._id === course._id);

            if (index >= 0) {
                
            state.cart.splice(index,1);
            state.totalItems--;
            state.price -= course.Price ;

            localStorage.setItem("cart", JSON.stringify(state.cart));
            localStorage.setItem("totalItems", JSON.stringify(state.totalItems));
            localStorage.setItem("price", JSON.stringify(state.price));

            toast.success("Product removed from cart");
            }

        },
        resetcart : (state) => {
            state.cart = []
            state.price = 0
            state.totalItems = 0

            localStorage.removeItem("cart")
            localStorage.removeItem("price")
            localStorage.removeItem("totalItems")
        }
    }
})

export const {addtocart,removefromcart,resetcart} = cartSlice.actions;

export default cartSlice.reducer;
