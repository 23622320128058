import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl';
import NewsBlock from "./NewsBlock";

function Catalog() {
const intl = useIntl();
return (
<div className="lg:flex lg:flex-col mx-[-1em] w-[76%] lg:mr-0 lg:w-full m-2 gap-3 justify-center border-[1px] rounded-[10px] shadow-[0_1px_3px_1px_#d3d9d9] p-[5px_10px_5px_10px] bg-[#FFF] lg:ml-4">
<div className="flex flex-col w-full justify-start">
<div className="text-2xl text-[#333399] font-bold mb-3 text-left">
<FormattedMessage id="catalog_title" defaultMessage="" />
</div>
<div className="whitespace-pre-line text-left text-[#6e8a99] text-[15px]">
<div dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: "catalog_content"}) }} />
</div>
<div className="bg-[#d4d4d4] border-none h-[1px]" />
</div>
<NewsBlock />
</div>
);
}
export default Catalog;
