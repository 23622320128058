import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import English from './lang/en.json';
import Russian from './lang/ru.json';
import { Toaster } from 'react-hot-toast';
import rootreducer from './reducers/index';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';


const store = configureStore({
  reducer: rootreducer,
})

const root = ReactDOM.createRoot(document.getElementById('root'));
const locale = navigator.language;

root.render(

  <Provider store={store}>
    <IntlProvider locale={locale} messages={English}>
      <App />
      <Toaster />
    </IntlProvider>
  </Provider>

);
reportWebVitals();
