import logo from './logo.svg';
import './App.css';
import Header from './layout/Header';
import About from './components/content/About';
import Footer from './layout/Footer';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Installation from './components/content/Installation';
import Warranty from './components/content/Warranty';
import Shipping from './components/content/Shipping';
import Contacts from './components/content/Contacts';
import Articles from './components/content/Articles';
import Backcall from './components/content/Backcall';
import ContentHeader from './layout/ContentHeader';
import Models from './components/content/Models';
import ModelItems from './components/Product/ModelItems';
import SecondMenu from './components/header/SecondMenu';
import Slider from './components/header/Slider';
import MediumMenu from './components/header/MediumMenu';
import Item from './components/Product/Item';
import Modelthumb from './components/Product/Modelthumb';
import Catalog from './components/content/Catalog';
import AdminPage from './components/content/AdminPage';
import Cart2 from './components/content/Cart2';

function App() {
  return (
    <div className="lg:w-[60vw] w-100vw mx-auto lg:overflow-x-visible md:overflow-x-hidden overflow-x-hidden">
      <Router>
        <Header />
        {/* <ContentHeader /> */}

        <div className='flex justify-center'>

          <div className='hidden lg:block'>
            <SecondMenu></SecondMenu>
          </div>

          <div className='flex flex-col items-center'>

            <div >
              <MediumMenu />
            </div>
            <Slider />

            <Routes>
              <Route path="/" element={<Catalog />} />
              <Route path="cart" element={<Cart2 />} />
              <Route path="about" element={<About />} />
              <Route path="installation" element={<Installation />} />
              <Route path="warranty" element={<Warranty />} />
              <Route path="shipping" element={<Shipping />} />
              <Route path="contacts" element={<Contacts />} />
              <Route path="articles" element={<Articles />} />
              <Route path="backcall" element={<Backcall />} />
              <Route path="catalog" element={<Catalog />} />
              <Route path="catalog/:name" element={<Models />} />
              <Route path="catalog/:name/:subcategory" element={<Modelthumb />} />
              <Route path="catalog/:name/:subcategory/:Itemname" element={<Item />} />
            </Routes>

          </div>

        </div>

        <Footer></Footer>
      </Router>

    </div>
  );
}

export default App;

