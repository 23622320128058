import { Link } from "react-router-dom";

function ContentHeader() {
    return (
      <div className="ContentHeader">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          <div className="flex flex-col border-[1px] rounded-[10px] overflow-hidden">
            <div>
              <img src="/images/catIMGcool.jpg" />
            </div>
            <div className="cooper_block flex items-end justify-center pb-[7px] text-[#0b85a4] underline">
              <Link to="/catalog/air_conditioners">Air conditioners</Link>
            </div>
          </div>
          <div className="flex flex-col border-[1px] rounded-[10px] overflow-hidden">
            <div>
              <img src="/images/catIMGhot.jpg" className="rounded-t-[10px]" />
            </div>
            <div className="cooper_block flex items-end justify-center pb-[7px] text-[#0b85a4] underline">
              <Link to="/catalog/heaters">Heaters</Link>
            </div>
          </div>
          <div className="flex flex-col border-[1px] rounded-[10px] overflow-hidden">
            <div>
              <img src="/images/catIMGdrier.jpg" className="rounded-t-[10px]" />
            </div>
            <div className="cooper_block flex items-end justify-center pb-[7px] text-[#0b85a4] underline">
              <Link to="/catalog/dehumidifiers">Dehumidifiers</Link>
            </div>            
          </div>
          <div className="flex flex-col border-[1px] rounded-[10px] overflow-hidden">
            <div>
              <img src="/images/catIMGhumidifier.jpg" className="rounded-t-[10px]" />
            </div>
            <div className="cooper_block flex items-end justify-center pb-[7px] text-[#0b85a4] underline">
              <Link to="/catalog/humidifiers">Humidifiers</Link>
            </div>            
          </div>
        </div>
      </div>
    );
  }
  
  export default ContentHeader;