import { endpoints } from "../apis";
import {apiConnector} from "../apiConnector";
import toast from "react-hot-toast";

const {GET_PRODUCTS_URL,GET_MODELS_URL,GET_MODELS_NAME_URL,CREATE_PRODUCT_URL,LOGIN_URL,MAIL_URL,BACKCALL_MAIL_URL} = endpoints;


export const getProducts = ({data}) => {
    return async () => {
      let result = [];
      
      try {

        const response = await apiConnector("POST", GET_PRODUCTS_URL,{
                name: data
        })

        result = response.data;

        if (!response.data.success) {
          throw new Error("There is some problem in fetching categories from server")
        };

        return result
      } catch (error) {
        toast.error("error in fetching Products");
      };

    }

  };

export const getModels = ({subcategory}) => {
    return async () => {

      let result = [];
      
      try {

        const response = await apiConnector("POST", GET_MODELS_URL,{
                subcategory: subcategory
        })

        result = response.data;

        if (!response.data.success) {
          throw new Error("There is some problem in fetching categories from server")
        };

        return result
      } catch (error) {
        toast.error("error in fetching Models");
      };

    }

  };

export const getModelsnames = ({name}) => {
    return async () => {

      let result = [];
      
      try {

        const response = await apiConnector("POST", GET_MODELS_NAME_URL,{
                name: name
        })

        result = response.data;

        if (!response.data.success) {
          throw new Error("There is some problem in fetching categories from server")
        };

        return result
      } catch (error) {
        toast.error("error in fetching Models names");
      };

    }

  };


export const createproduct = (data) => {
    return async () => {

      let result = [];
      
      try {

        const response = await apiConnector("POST",CREATE_PRODUCT_URL,data)

        result = response.data;

        if (!response.data.success) {
          throw new Error("There is some problem in creating product")
        };

        return result
      } catch (error) {
        toast.error("error in creating product");
      };

    }

  };


  export const Mailsender = (data) => {
    return async () => {
      // Start loading toast and capture its ID
      const toastId = toast.loading("Sending mail...");
  
      try {
        const response = await apiConnector("POST", MAIL_URL, data);
  
        if (!response.data.success) {
          throw new Error("There is some problem in sending mail");
        }

        toast.dismiss(toastId);
  
        return response;
      } catch (error) {
        console.log(error);
        console.log("message =>",error.message)
        toast.error("Please type a valid email and phone number");
      }
    };
  };

  export const BackcallMailsender = (data) => {
    return async () => {
      // Start loading toast and capture its ID
      const toastId = toast.loading("Sending mail...");
  
      try {
        const response = await apiConnector("POST", BACKCALL_MAIL_URL, data);
  
        if (!response.data.success) {
          throw new Error("There is some problem in sending mail");
        }

        toast.dismiss(toastId);
  
        return response;
      } catch (error) {
        console.log(error);
        console.log("message =>",error.message)
        toast.error("Error in sending mail please try again later");
      }
    };
  };
  

export const UserLogin = (data) => {
    return async () => {

      let result = [];
      
      try {

        const response = await apiConnector("POST",LOGIN_URL,data)

        result = response.data;

        if (!response.data.success) {
          throw new Error("There is some problem in creating product")
        };

        return result
      } catch (error) {
        toast.error("incorrect username and password");
      };

    }

  };
