import { useParams } from "react-router-dom";
import { IoCheckbox } from "react-icons/io5";
import { useEffect, useState } from "react";
import { getModelsnames } from "../../services/operations/Productapi";
import { useDispatch } from "react-redux";
import { addtocart } from "../../slices/CartSlice";

function Item() {

  const [ItemData, setItemData] = useState(null);

  const { Itemname } = useParams();

  const [exchange_rate, setexchange_rate] = useState(false);

  const dispatch = useDispatch();


  const fecher = async (data) => {

    const result = await getModelsnames({ name: Itemname })();

    if (result) {

      setItemData(result);
      console.log(result.data);
    }

  }

  const imagePath = (Itemcode) => {
    try {
      if (Itemcode) {
        // Dynamically import the image
        const Path = require(`../../images/${Itemcode}.jpg`);
        return Path;
      } else {
        // Default image if no item code is provided
        const defaultPath = require(`../../images/multi-split-systems.jpg`);
        return defaultPath;
      }
    } catch (error) {
      // Handle missing image gracefully
      const fallbackPath = require(`../../images/multi-split-systems.jpg`);
      return fallbackPath;
    }
  };

const pdfPath = (subcategory) => {
    try {
      if (subcategory) {
        // Dynamically import the image
        const Path = require(`../../images/${subcategory}.pdf`);
        return Path;
      } else {
        // Default image if no item code is provided
        const defaultPath = require(`../../images/jonathan.pdf`);
        return defaultPath;
      }
    } catch (error) {
      // Handle missing image gracefully
      const fallbackPath = require(`../../images/jonathan.pdf`);
      return fallbackPath;
    }
  };

  // Extract the model number from the product name (for example: "CH-S07FTXF2-NG R32 Wi-Fi")
  const modelNumberMatch = Itemname.match(/S(\d+)/);
  // Get the numeric part from the match if available
  const modelNumber = modelNumberMatch ? modelNumberMatch[1] : null;
  // Convert the numeric part to an integer
  const modelNumberInt = modelNumber ? parseInt(modelNumber, 10) : null;

  // Optionally, perform actions based on the extracted model number



  useEffect(() => {

    fecher(Itemname)
    setexchange_rate("42");

  }, [Itemname]);



  return (
    <div>
      {
        ItemData != null ? (

          <div className="ml-5" >
            <h2 className=" text-[1.8rem] text-[#333399] font-bold mb-[0.5rem] mt-[1rem] textshadow lg:ml-0 ml-16">{ItemData.data.name}</h2>
            <hr className=" my-2 h-2"></hr>
            <div className=" flex lg:flex-row md:flex-row flex-col w-full">
              <div className="lg:w-[50%] md:w-[50%] w-[80%] mx-auto mt-2">
                <img src={imagePath(ItemData.data.ItemCode)} className="w-full h-[80%]" alt={ItemData.data.name}></img>
              </div>

              <div className=" bg-[#F7F7F7] lg:w-[50%] lg:h-[80%]  flex flex-col text-[14px]
               justify-center lg:items-start items-center ml-4 gap-4 md:w-[50%] md:h-[80%] w-[80%] mx-auto pl-6 py-4 ">

                <p className="bg-[#FFF7D2] w-[140px] px-[8px] py-[5px] text-xl font-bold">{exchange_rate ? Math.round(ItemData.data.Price * exchange_rate) : "Loading"} UAH.</p>

                <p>Виробник : <span className=" font-bold">{ItemData.data.Manufacturer}</span> </p>

                <p>Вага товару : <span className=" font-bold">{ItemData.data.Product_Weight} кг</span> </p>

           {
		ItemData.data.category === "Multi-split systems" ? "" :

		<p>Інструкція з експлуатації: <span className=" font-bold"><a href={pdfPath(ItemData.data.subcategory)} target="_blank" rel="noopener noreferrer" className=" text-blue-500">Відкрити інструкцію</a></span> </p>
		}

		<p>{ItemData.Product_Weight}</p>

                {modelNumberInt !== null && (
                  <p className="text-sm text-gray-700 lg:self-start self-center">
                    {
                      modelNumberInt === 7 && "Площа приміщення до - 20" 
                    }
                    {
                      modelNumberInt === 9 && "Площа приміщення до - 25" 
                    }
                    {
                      modelNumberInt === 12 && "Площа приміщення до - 35" 
                    }
                    {
                      modelNumberInt === 18 && "Площа приміщення до - 50" 
                    }
                    {
                      modelNumberInt === 24 && "Площа приміщення до - 70" 
                    }
                  </p>
                )}

                <p className="flex items-center"><IoCheckbox className={`${ItemData.data.Available_in_stock > 0 ? "text-green-400" : "text-gray-600"}`} /> {ItemData.data.Available_in_stock ? " В наявності на складі " : " Закінчився "}</p>

                <button className="bg-[#2082D9] text-white text-lg p-2 rounded-md" onClick={() => dispatch(addtocart(ItemData.data))}>Додати в корзину</button>

              </div>

            </div>



            <div className="text-black mx-3 lg:ml-2 ml-5 lg:mt-[-50px]">
              <h2 className="text-lg font-bold">Основні функції:</h2>
              <div className="mt-4 text-[14px]">
                {ItemData.data.main_functions ? ItemData.data.main_functions.split(';').map((desc, index) => (
                  <p key={index} className="mb-2">
                    {desc.trim()}
                  </p>
                )) : (<p>No data to show</p>)}
              </div>
            </div>

            <div className="text-black mx-3 lg:ml-2 ml-5 text-[15px]">
              <h2 className="text-lg font-bold">Основні характеристики:</h2>
              <div className="mt-4">
                {ItemData.data.description.split(';').map((desc, index) => (
                  <p key={index} className="mb-2">
                    {desc.trim()}
                  </p>
                ))}
              </div>
            </div>

          </div>
        )

          : (<div>Loading</div>)


      }
    </div>

  )
}

export default Item;
