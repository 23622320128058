import React, { useState } from 'react'
import { CiMenuBurger } from "react-icons/ci";
import { FaPhoneVolume } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import SecondMenu from './SecondMenu';
import SearchBox from './SearchBox';
import { Link } from 'react-router-dom';
import { FaShoppingCart } from "react-icons/fa";
import { useSelector } from 'react-redux';


const MediumMenu = () => {

    const [menu, setmenu] = useState(false);
    const [search, setsearch] = useState(false);
    const [mainmenu, setmainmenu] = useState(false);
    const { cart } = useSelector((state) => state.cart);
    return (
        <div>
            <div className='navbar lg:hidden bg-[#ECECEC] w-[100vw] py-4 border-[1px] flex justify-around items-center pr-11'>

                <div className='flex items-center justify-center gap-2 bg-[#F8D47F] py-2 px-2 border-[1px] border-black rounded-sm' onClick={() => {setmenu(!menu);
                    setmainmenu(false);
                    setsearch(false)
                }}>
                    <CiMenuBurger />
                    Каталог
                </div>

                <div className=' text-xl border-[1px] border-black rounded-sm py-2 px-2'>
                    <FaPhoneVolume />
                </div>
	    {/*
                <div className=' text-xl border-[1px] border-black rounded-sm py-2 px-2' onClick={() => 
                    {setsearch(!search);
                    setmainmenu(false);
                    setmenu(false)}
                }>
                    <FaSearch />
                </div>
*/}
                <div className=' text-xl border-[1px] border-black rounded-sm py-2 px-2' onClick={() => {
                    setmainmenu(!mainmenu);
                    setmenu(false);
                    setsearch(false)}}>
                    <GiHamburgerMenu />
                </div>
	 <div className=" text-4xl lg:hidden mb-6 cursor-pointer">
                <Link to={"/cart"}>
                <div className=" text-white bg-blue-600 w-[35px] h-[35px] border-1 text-[13px] rounded-full text-center relative top-4 left-[18px]">
                  {
                    cart.length
                  }
                </div>
                <FaShoppingCart />
                </Link>
              </div>


            </div>

            <div className={`${menu ? "block" : "hidden"} lg:hidden ml-2`}>
                <SecondMenu small={true} />
            </div>

            <div className={`${search ? "block" : "hidden"} lg:hidden mt-4`}>
                <SearchBox />
            </div>

            <div className={`${mainmenu? "block" : "hidden"} lg:hidden mt-4`}>

                <div className="bg-[#ECECEC]">
                    <ul className=' bg-[#f2f2f2b9]'>
	    		
	    		<Link to={"catalog/Wall_Conditioners"}>
                            <h2 className='border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700' >Фірмовий магазин</h2>
                        </Link>
                        <Link to={"/about"}>
                            <h2 className='border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700' >Про компанію C&H1</h2>
                        </Link>

                        <Link to={"/installation"}>
                            <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700'>Монтаж</h2>
                        </Link>
                        <Link to={"/warranty"}>
                            <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700'>Сервіс та гарантія</h2>
                        </Link>
	    {/*                        <Link to={"/shipping"}>
                            <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700'>Доставка</h2>
                        </Link>*/}
                        <Link to={"/contacts"}>
                            <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700'>Контакти</h2>
                        </Link>
                        <Link to={"/articles"}>
                            <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700'>Статті</h2>
                        </Link>
                        <Link to={"/backcall"}>
                            <h2 className=' border-b-[#a8a8a8b9] border-solid border-b-[1px] pr-[70px] p-2 text-blue-700'>Зворотній звязок</h2>
                        </Link>
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default MediumMenu
