function PhoneInfo() {
  return (
    <div className="flex flex-col items-center maxcont">
	 
      <span className="font-bold text-[#0b85a4] text-[10px] lg:text-[12px]">+38 (068) 310-15-65</span>
      <span className="font-bold text-[#0b85a4] text-[10px] lg:text-[12px]">+38 (032) 245-58-83</span>
      <span className="font-bold text-[#0b85a4] text-[10px] lg:text-[12px]">+38 (032) 232-42-61</span>
    </div>
  );
}

export default PhoneInfo;
