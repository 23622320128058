import SearchBox from "../components/header/SearchBox";
import PhoneInfo from "../components/header/PhoneInfo";
import Logo from "../components/header/Logo";
import Menu from "../components/header/Menu";
import { Link } from "react-router-dom";
import logo1 from "../images/Logo_Vector.png";
import { FaShoppingCart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
function Header() {
const { cart } = useSelector((state) => state.cart);
return (
<div className="Header flex flex-col flex-wrap w-full m-5 gap-3 md:flex-row justify-center md:justify-start">
<div className=" flex md:items-center md:justify-center items-center justify-center w-[90vw] gap-[50px] md:gap-[400px]">
<div className=" flex-none">
<Link to={"/"}>
<Logo />
</Link>
</div>
<div className=" w-[500px] flex justify-center items-center large11">
<Link to={"/"}>
<img src={logo1} className="w-[70px] lg:w-[160px]"/>
</Link>
<PhoneInfo></PhoneInfo>
</div>
</div>


<div className="flex flex-col grow flex-wrap w-full items-center align-center gap-3 md:flex-row md:w-auto lg:ml-[40px] large1">
<div className="flex flex-col w-full">
<div className="flex items-center justify-center md:justify-start mr-[80px] gap-3">
<div className=" text-4xl lg:absolute lg:block hidden left-[20%] cursor-pointer large2">
<Link to={"/cart"}>
<div className=" text-white bg-blue-600 w-[35px] h-[35px] border-1 text-[13px] rounded-full text-center relative top-4 left-[18px] ">
{
cart.length
}
</div>
<FaShoppingCart />
</Link>
</div>
<div className=" md:hidden lg:block hidden">
<Menu />
</div>

</div>
</div>
</div>
</div>
);
}
export default Header;
