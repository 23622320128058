import { Link, useParams } from "react-router-dom";
import { getModels } from "../../services/operations/Productapi";
import { useEffect, useState } from "react";
import image2 from "../../images/multi-split-systems.jpg"
import { FormattedMessage } from "react-intl";
import { IoCheckbox } from "react-icons/io5";


function Modelthumb() {

  const [ModelData, setModelData] = useState("");
  const [exchange_rate, setexchange_rate] = useState(false);

  const { subcategory } = useParams();

  const fecher = async (data) => {

    const result = await getModels({ subcategory: subcategory })();

    if (result) {

      setModelData(result);
    }

  }

  const imagePath = (Itemcode) => {
    try {
      if (Itemcode) {
        // Dynamically import the image
        const Path = require(`../../images/${Itemcode}.jpg`);
        return Path;
      } else {
        // Default image if no item code is provided
        const defaultPath = require(`../../images/multi-split-systems.jpg`);
        return defaultPath;
      }
    } catch (error) {
      // Handle missing image gracefully
      const fallbackPath = require(`../../images/multi-split-systems.jpg`);
      return fallbackPath;
    }
  };

  useEffect(() => {

    fecher(subcategory);
    setexchange_rate("42");

  }, [subcategory]);

  return (

    <div className="Models flex flex-col flex-wrap ml-[-3em] w-[86%] lg:w-full m-2 justify-center border-[1px] rounded-[10px] shadow-[0_1px_3px_1px_#d3d9d9] p-[5px_10px_5px_10px] lg:ml-4 bg-[#FFF] mb-[30%]">
      
      <div className="flex flex-col w-full justify-start">
        <div className="text-[24px] text-[#333399] font-bold mb-3 text-left">
          {subcategory &&
            subcategory === 'Vital' ? <FormattedMessage id="category.wall_conditioners.Vital" defaultMessage="Vital" /> :
            subcategory === 'Alpha' ? <FormattedMessage id="category.wall_conditioners.Alpha" defaultMessage="Alpha" /> :
              subcategory === 'Veritas' ? <FormattedMessage id="category.wall_conditioners.Veritas" defaultMessage="Veritas" /> :
                subcategory === 'Arctic' ? <FormattedMessage id="category.wall_conditioners.Arctic" defaultMessage="Arctic" /> :
                  subcategory === 'Daytona' ? <FormattedMessage id="category.wall_conditioners.Daytona" defaultMessage="Daytona" /> :
                    subcategory === 'Supreme' ? <FormattedMessage id="category.wall_conditioners.Supreme" defaultMessage="Supreme" /> :
                      subcategory === 'Majesty' ? <FormattedMessage id="category.wall_conditioners.Majesty" defaultMessage="Majesty" /> :
                        subcategory === 'ICY' ? <FormattedMessage id="category.wall_conditioners.ICY_ІІІ" defaultMessage="ICY ІІІ" /> :
                          subcategory === 'Avalon' ? <FormattedMessage id="category.wall_conditioners.Avalon" defaultMessage="Avalon" /> :
                            subcategory === 'Supreme Continental' ? <FormattedMessage id="category.wall_conditioners.Supreme_Continental" defaultMessage="Supreme_Continental" /> :
                            subcategory === 'Imperial' ? <FormattedMessage id="category.wall_conditioners.Imperial" defaultMessage="Imperial" /> :
                              subcategory === 'VIP' ? <FormattedMessage id="category.wall_conditioners.VIP" defaultMessage="VIP" /> :
                                subcategory === 'Consol' ? <FormattedMessage id="category.wall_conditioners.Consol" defaultMessage="Consol" /> :
                                  subcategory === 'Nature' ? <FormattedMessage id="category.wall_conditioners.Nature" defaultMessage="Nature" /> :
                                    subcategory === 'External' ? <FormattedMessage id="category.multi_split_systems.External" defaultMessage="Зовнішні блоки " /> :
                                      subcategory === 'Vital(I)' ? <FormattedMessage id="category.multi_split_systems.Vital(I)" defaultMessage="Внутрішні блоки Cooper&Hunter серія Vital Inverter NG(I) " /> :
                                        subcategory === 'Veritas(I)' ? <FormattedMessage id="category.multi_split_systems.Veritas(I)" defaultMessage="Внутрішні блоки Cooper&Hunter серія Veritas(I)" /> :
                                          subcategory === 'Alpha(I)' ? <FormattedMessage id="category.multi_split_systems.Alpha(I)" defaultMessage="Alpha(I)" /> :
                                            subcategory === 'Daytona(I)' ? <FormattedMessage id="category.multi_split_systems.DAYTONA(I)" defaultMessage="DAYTONA(I)" /> :
                                              subcategory === 'Supreme Continental(I)' ? <FormattedMessage id="category.multi_split_systems.SUPREME CONTINENTAL(I)" defaultMessage="Supreme Continental(I)" /> :
                                              subcategory === 'Cassette air conditioners(I)' ? <FormattedMessage id="category.multi_split_systems.Cassette air conditioners(I)" defaultMessage="Cassette air conditioners(I)" /> : 
                                              subcategory === 'Consol(I)' ? <FormattedMessage id="category.multi_split_systems.Console(I)" defaultMessage="Consol(I)" /> : 
                                              subcategory === 'Duct air conditioners(I)' ? <FormattedMessage id="category.multi_split_systems.Duct air conditioners(I)" defaultMessage="Duct air conditioners(I)" /> : ""
          }
        </div>
      </div>

      <div className=" grid grid-cols-1 lg:grid-cols-3 lg:w-[100%] md:w-[80%] w-[80%] gap-3">
        {
          ModelData &&
          ModelData.data.map((details) => {

            // Extract the model number from the product name (for example: "CH-S07FTXF2-NG R32 Wi-Fi")
            const modelNumberMatch = details.name.match(/S(\d+)/);
            // Get the numeric part from the match if available
            const modelNumber = modelNumberMatch ? modelNumberMatch[1] : null;
            // Convert the numeric part to an integer
            const modelNumberInt = modelNumber ? parseInt(modelNumber, 10) : null;

            // Optionally, perform actions based on the extracted model number


            return (
              <div className="Item flex flex-col items-center justify-center border-[1px] border-gray-400 py-6 gap-3" key={details._id}>
                <div>
                  <img src={imagePath(details.ItemCode)} className="w-[200px]" alt={details.name}></img>
                </div>
                <div>
                  <span>
                    <Link to={`${details.name}`}>
                      <div className="cursor-pointer text-blue-600 text-xl font-bold ml-4">
                        {details.name}
                      </div>
                    </Link>
                  </span>

                  {modelNumberInt !== null && (
                    <p className="text-sm text-gray-700 self-start ml-4 text-[14px]">
                      {
                        modelNumberInt === 7 && "Площа кімнати до - 25м"
                      }
                      {
                        modelNumberInt === 9 && "Площа кімнати до - 25м"
                      }
                      {
                        modelNumberInt === 12 && "Площа кімнати до - 35м"
                      }
                      {
                        modelNumberInt === 18 && "Площа кімнати до - 50м"
                      }
                      {
                        modelNumberInt === 24 && "Площа кімнати до - 70м"
                      }
                    </p>
                  )}


                  <div className="flex items-center justify-center mt-4 gap-3 text-[14px]">
                    <div>
                      <p className="flex items-center">
                        <IoCheckbox
                          className={`${details.Available_in_stock > 0
                            ? "text-green-400"
                            : "text-gray-600"
                            }`}
                        />{" "}
                        {details.Available_in_stock
                          ? "В наявності"
                          : "Закінчився"}
                      </p>
                    </div>

                    <div>
                      <p className="bg-[#FFF7D2] w-[140px] px-[8px] py-[2px] text-xl font-bold">
                        {exchange_rate
                          ? Math.round(details.Price * exchange_rate)
                          : "Loading"}{" "}
                        UAH.
                      </p>
                    </div>
                  </div>
                </div>
              </div>)
          })
        }



      </div>
    </div>

  );

}

export default Modelthumb;
